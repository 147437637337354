<template>
  <div>
    <b-button
      size="sm"
      variant="flat-primary"
      @click="handleClick"
      class="float-right"
    >
      <i class="bi bi-play font-medium-5"></i>
    </b-button>
    <b-modal
      v-if="currentData"
      v-model="playModal"
      :title="currentData.display_name"
      centered
      hide-footer
    >
      <div class="my-2" v-html="htmlcontent"></div>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton, BModal
} from "bootstrap-vue";
export default {
  components: {
    BButton,
    BModal

  },
  props: ["value"],

  methods: {
    handleClick() {
      console.log(this.value);
      const foundNode = globalThis.editor.nodes.find(node => node.id === this.value);
      console.log("foundNode", foundNode);
      this.currentData = JSON.parse(foundNode.options.get("Announcement")._value);
      console.log("currentData", this.currentData);
      this.playModal = true;

      let path =
        `${globalThis.env["PROTOCOL"]}://${globalThis.env.SOUND_URL}/sounds/` +
        this.currentData.internal_name +
        ".wav";

      this.htmlcontent = `<audio id="voyce_audio" ref="player" controls preload="none" style = "width:100%">
                <source src="${path}" type="audio/mpeg">
                Your browser does not support the audio element.
              </audio>`;
      console.log("Bu düğümde işlem yapılıyor:", foundNode);
      // Diğer işlemler...
    }
  },
  mounted: function () {
    console.log(this.value);
  },
  data() {
    return {
      playModal: false,
      placeHolder: '',
      htmlcontent: '',
      currentData: null
    }
  },
}
</script>
