import { Node } from "@baklavajs/core";

export class QuestionNode extends Node {
  twoColumn = true;

  constructor() {
    super();
    this.type = "QuestionNode";
    this.name = "Question";

    this.addOption("Announcement", "SelectOption", '', undefined, {
      items: globalThis.IVRElements.AnnouncementNode,
      displayName: "Announcement",
    })
    this.addOption("Timeout", "IntegerOption", 3, undefined, { displayName: "Timeout (Sec)" })
    this.addOption("Retry", "IntegerOption", 3, undefined, { displayName: "Faulty Inputs" })
    this.addOption("Question", "InputOption", "", undefined, { displayName: "Question" })
    // this.addOption("QuestionLength", "IntegerOption", 0, undefined, { displayName: "QuestionLength" })
    this.addOption("cPlayButton", "cPlayButton", this.id);

    this.addInputInterface("In");
    this.addOutputInterface("Next");



  }
}
