import { Node } from "@baklavajs/core";

export class VoiceMailNode extends Node {
  twoColumn = true;

  constructor() {
    super();
    this.type = "VoiceMailNode";
    this.name = "VoiceMail";
    this.addOption("EMail", "SelectOption", '', undefined, {
      items: globalThis.IVRElements.EMailNode,
      displayName: "EMail",
    })
    this.addOption("EMail Provider", "SelectOption", '', undefined, {
      items: globalThis.IVRElements.EMailProvidersNode,
      displayName: "EMail Provider",
    })
    this.addInputInterface("In");

  }
}
