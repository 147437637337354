<template>
  <textarea
    :placeholder="placeHolder"
    rows="5"
    class="dark-input"
    v-model="localValue"
  ></textarea>
</template>

<script>
export default {
  props: ["value"],
  watch: {
    localValue(newVal) {
      this.$emit("input", newVal);
    },
    value(newVal) {
      this.localValue = newVal;
    }
  },
  mounted: function () {
    this.placeHolder = this.value;
    if (this.value == 'Body') {
      this.localValue = `{\n"RemoteNumber":"{{RemoteNumber}}",\n"InteractionID":"{{InteractionID}}"\n}`;
    }
    else if (this.value == 'Header') {
      this.localValue = `{\n"Authorization":"your-token"\n}`;
    }
    else if (this.value == 'Text') {
      this.localValue = '';
    }
    else if (this.value == 'Value') {
      this.localValue = '';
    }
    else {
      this.localValue = this.value;
    }
  },
  data() {
    return {
      placeHolder: '',
      localValue: ''
    }
  },
}
</script>
