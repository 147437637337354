<template>
  <b-card class="mb-0" border-variant="primary">
    <draggable
      class="dragArea list-group card"
      tag="ul"
      :list="tasks"
      :group="{ name: 'g1', type: type }"
      @add="changeItemId"
    >
      <li v-for="(el, i) in tasks" :key="i" :id="i">
        <b-button
          size="sm"
          @click="editItem(el)"
          class="ma-50"
          variant="primary"
          >{{ displayName(el) }}
        </b-button>
        <b-button
          size="sm"
          @click="removeAt(i)"
          class="btn-icon ml-50"
          variant="flat-danger"
        >
          <feather-icon icon="Trash2Icon" />
        </b-button>
        <b-card
          class="mb-0"
          border-variant="gradient-primary"
          v-if="el.name == 'tc' || el.name == 'menu'"
        >
          <h5 style="color: black" v-if="el.name == 'tc'">
            {{ lang("t_matched") }}
          </h5>
          <nested-draggable :tasks="el.tasks" :click="click" :type="el.name" />
        </b-card>
        <b-card
          class="mb-0"
          border-variant="gradient-primary"
          v-if="el.name == 'tc'"
        >
          <h5 style="color: black">{{ lang("t_unmatched") }}</h5>
          <nested-draggable :tasks="el.tasks2" :click="click" :type="el.name" />
        </b-card>
      </li>
    </draggable>
    <!-- <v-dialog v-model="modal" persistent max-width="500">
      <v-card>
        <v-card-title class="headline"
          ><h3>{{ lang("t_areYouSure") }}</h3></v-card-title
        >
        <v-card-text style="font-size: 18px">{{
          lang("t_areYouSureYouWantToDeleteTheSelectedRecord")
        }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <b-button size="sm" text tile color="green darken-1" @click="modal = false">
            {{ lang("t_save") }}
          </b-button>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
  </b-card>
</template>

<script>
// import {
//   VChip,
//   VCard,
//   VBtn,
//   VDialog,
//   VCardTitle,
//   VCardText,
//   VCardActions,
//   VSpacer,
//   VIcon,
// } from "vuetify/lib";
import { BButton, BRow, BCol, BBadge, BFormInput, BFormGroup, BCard, BCardText, BLink } from 'bootstrap-vue'
import draggable from "vuedraggable";
export default {
  props: {
    tasks: {
      required: true,
      type: Array,
    },
    tasks2: {
      required: false,
      type: Array,
    },
    click: {
      required: false,
      type: Function,
    },
    type: "",
  },

  components: {
    draggable,
    BButton, BRow, BCol, BBadge, BFormInput, BFormGroup, BCard, BCardText, BLink
  },
  name: "nested-draggable",
  data: () => ({
    lang: _l(window.localStorage.getItem('language') || "tr"),
    modal: false,
    selected_item: {},
  }),
  methods: {
    displayName(el) {
      var text = "";
      if (el.parent === "menu" && el.name != "announce" && el.dtmf != "") {
        text += el.dtmf + " / ";
      }
      text += el.display_name;

      if (el.target != undefined) {
        if (
          el.target.display_name !== "" &&
          el.target.display_name != undefined &&
          el.target.display_name.length > 0
        ) {
          text += " / " + el.target.display_name;
        }
      }

      return text;
    },
    editItem(item) {
      this.click(item);
    },
    removeAt(idx) {
      this.tasks.splice(idx, 1);
    },
    changeItemId(e) {
      console.log("changeItemId:", e); // e.to.__vue__.$attrs.group.type
      e.item._underlying_vm_.parent = e.to.__vue__.$attrs.group.type;
    },
  },
};
</script>
<style scoped>
ul {
  list-style: none;
}
.dragArea {
  margin-bottom: 10px;
  padding-bottom: 10px;
  min-height: 40px;
  margin-right: 12px;
  margin-left: 3px;
  color: #1976d2;
}
.card {
  box-shadow: 2px 5px 8px 0 rgba(0, 0, 0, 0.35);
  transition: 0.3s;
  border: 0.1px #1976d2;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.35);
}
</style>
