import { Node } from "@baklavajs/core";

export class HangupNode extends Node {
  twoColumn = true;

  constructor() {
    super();
    this.type = "HangupNode";
    this.name = "Hangup";
    this.addInputInterface("Hangup");
  }
}
