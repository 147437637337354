<template>
    <div
      :class="['dark-select', { '--open': open }]"
      @click="open = !open"
      v-click-outside="() => { open = false; }"
    >
      <div class="__selected">
          <div class="__text">{{ selectedText }}</div>
          <div class="__icon">
              <i-arrow></i-arrow>
          </div>
      </div>
      <transition name="slide-fade">
          <div class="__dropdown" v-show="open">
              <div class="item --header">{{ name }}</div>
              <div
                  v-for="(item, i) in items"
                  :key="i"
                  :class="['item', { '--active': isSelected(item) }]"
                  @click="setSelected(item)"
              >{{ isAdvancedMode ? item.text : item }}</div>
          </div>
      </transition>
    </div>
</template>

<script>
export default {
    props: ["option", "node", "value"],
    data: () => {
        return {
            count: 0
        };
    }
};
</script>
