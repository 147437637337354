import { Node } from "@baklavajs/core";

export class VariableNode extends Node {
  twoColumn = true;

  constructor() {
    super();
    this.type = "VariableNode";
    this.name = "Variable";

    this.addOption("Announcement", "SelectOption", '', undefined, {
      items: globalThis.IVRElements.AnnouncementNode,
      displayName: "Announcement",
    })
    this.addOption("Timeout", "IntegerOption", 3, undefined, { displayName: "Timeout (Sec)" })
    this.addOption("Retry", "IntegerOption", 3, undefined, { displayName: "Faulty Inputs" })
    this.addOption("Variable", "InputOption", "", undefined, { displayName: "Variable" })
    // this.addOption("Value", "InputOption", "", undefined, { displayName: "Value" })
    this.addOption("VariableLength", "IntegerOption", 0, undefined, { displayName: "VariableLength" })
    this.addOption("TimeBetweenKeys", "IntegerOption", 2, undefined, { displayName: "TimeBetweenKeys" })

    this.addOption("Hide From Agent", "CheckboxOption")
    this.addOption("Show In Call Details", "CheckboxOption")

    this.addOption("cPlayButton", "cPlayButton", this.id);

    this.addInputInterface("In");
    this.addOutputInterface("Next");



  }
}
