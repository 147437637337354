<template>
  <div>
    <b-card no-body style="height: 75vh; overflow-y: auto">
      <b-card-text class="mt-2 mx-1">
        <b-tabs
          vertical
          pills
          active-nav-item-class="bg-light-primary border-0 shadow-none cursor-pointer"
          lazy
        >
          <b-tab title-link-class="float-left " active>
            <template #title>
              <i class="bi bi-bezier font-medium-1"></i>

              <span>{{ lang("t_IVR") }}</span>
            </template>

            <ivr></ivr>
          </b-tab>
          <b-tab title-link-class="float-left">
            <template #title>
              <i class="bi bi-bezier2 font-medium-1"></i>

              <span>{{ lang("t_dialerPrecall") }}</span>
            </template>

            <dialer-precall></dialer-precall>
          </b-tab>
          <b-tab title-link-class="float-left ">
            <template #title>
              <i class="bi bi-diagram-2 font-medium-1"></i>

              <span>{{ lang("t_vIVR") }}</span>
            </template>

            <variable-ivr></variable-ivr>
          </b-tab>
          <b-tab title-link-class="float-left ">
            <template #title>
              <i class="bi bi-list-check font-medium-1"></i>

              <span>{{ lang("t_survey") }}</span>
            </template>

            <survey></survey>
          </b-tab>
        </b-tabs>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import VSelect from './Options/VSelect.vue'
import ivr from './IVR.vue'
import VariableIvr from './vIVR.vue'
import Survey from './Survey.vue'
import DialerPrecall from './DialerPrecall.vue'


import {
  BCardTitle,
  BFormCheckbox,
  VBModal,
  BModal,
  BButton,
  BRow,
  BCol,
  BBadge,
  BFormInput,
  BFormGroup,
  BCard,
  BCardText,
  BLink,
  BFormTextarea,
  BFormRadio,
  BDropdown,
  BDropdownItem,
  BTabs,
  BTab
} from "bootstrap-vue";



export default {
  components: {
    ivr,
    DialerPrecall,
    VariableIvr,
    Survey,
    VSelect,
    ToastificationContent,
    BFormCheckbox,
    BCardTitle,
    BModal,
    BButton,
    BRow,
    BCol,
    BBadge,
    vSelect,
    BCard,
    BCardText,
    BLink,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BFormRadio,
    BDropdown,
    BDropdownItem,
    BTabs,
    BTab
  },
  directives: {
    "b-modal": VBModal,
  },


  methods: {

  },
  mounted: async function () {

  },

  data() {
    return {
      lang: _l(window.localStorage.getItem("language") || "tr"),
      types: [
        { internal_name: 'ivr', display_name: globalThis._lang('t_IVR') },
        { internal_name: 'dialer_precall', display_name: globalThis._lang('t_dialerPrecall') },
        { internal_name: 'variable_ivr', display_name: globalThis._lang('t_vIVR') },
        { internal_name: 'survey', display_name: globalThis._lang('t_survey') },
      ],
      selected_type: 'ivr'
    };
  },
};
</script>

<style  lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
