import { Node } from "@baklavajs/core";

export class ReturnAgentNode extends Node {
  twoColumn = true;

  constructor() {
    super();
    this.type = "ReturnAgentNode";
    this.name = "ReturnAgent";
    this.addInputInterface("In");
  }
}
