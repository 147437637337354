import { Node } from "@baklavajs/core";

export class AssignAgentNode extends Node {
  twoColumn = true;

  constructor() {
    super();
    this.type = "AssignAgentNode";
    this.name = "AssignAgent";
    this.addInputInterface("In")
    this.addOutputInterface("Timeout")

    this.addOption("Agent", "InputOption", "", undefined, { displayName: "Agent" });
    this.addOption("Timeout", "IntegerOption", 3, undefined, { displayName: "Timeout (Sec)" })
  }
}
