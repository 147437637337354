import { Node } from "@baklavajs/core";

export class ConditionNode extends Node {
  twoColumn = true;
  width = 500;
  constructor() {
    super();
    this.type = "ConditionNode";
    this.name = "Condition";
    this.addOption("Rule", "InputOption", "", undefined, { displayName: "Rule" });

    this.addOption("Operand", "SelectOption", "", undefined, {
      items: globalThis.IVRElements.Operand,
    });
    this.addOption("Value", "TextArea", "Value", 'Value', { displayName: "Value" });
    // this.addOption("Value", "InputOption", "", undefined, { displayName: "Value" });

    this.addInputInterface("In");
    this.addOutputInterface("Match");
    this.addOutputInterface("No Match");
  }
}
