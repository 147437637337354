import { Node } from "@baklavajs/core";

export class STTNode extends Node {
  twoColumn = true;

  constructor() {
    super();
    this.type = "STTNode";
    this.name = "STT";
    this.addOption("Variable", "InputOption", "", undefined, { displayName: "Variable" })

    this.addOption("STT", "SelectOption", "", undefined, {
      items: []
    });
    this.addOption("Timeout", "IntegerOption", 3, undefined, { displayName: "Timeout (Sec)" })

    this.addOption("Beep Tone", "CheckboxOption", false, undefined, {
      additionalProps: {
        customClass: "custom-control-primary"
      }
    });

    this.addInputInterface("In");
    this.addOutputInterface("Next");

  }
}
