import { Node } from "@baklavajs/core";

export class DialerHangupNode extends Node {
  twoColumn = true;

  constructor() {
    super();
    this.type = "DialerHangupNode";
    this.name = "Hangup";
    this.addOption("Reason", "InputOption", "", undefined, { displayName: "Reason" });

    this.addInputInterface("Hangup");
  }
}
