<template>
  <div>
    <b-custom-spinner :is_progress="is_progress"></b-custom-spinner>

    <b-row>
      <b-col>
        <b-card no-body>
          <b-card-header class="d-block">
            <b-card-title>
              {{ lang("t_lineGroup") }}
            </b-card-title>
            <!-- <b-card-sub-title class="mt-1">
              {{ lang("t_queueGroup") }}
            </b-card-sub-title> -->
          </b-card-header>

          <b-card-body class="px-0">
            <b-card-text class="d-flex mx-2">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>

                <b-form-input
                  v-model="searchQuery"
                  :placeholder="lang('t_search')"
                ></b-form-input>
              </b-input-group>

              <b-button
                @click="
                  modal_record_details = true;
                  isEdit = false;
                  reset_record();
                "
                size="sm"
                class="px-50 py-50 ml-2"
                variant="flat-secondary"
              >
                <b-icon icon="plus"></b-icon>
              </b-button>
              <b-button size="sm" class="px-50 py-50" variant="flat-secondary">
                <b-icon icon="funnel"></b-icon>
              </b-button>
            </b-card-text>
            <b-table
              :filter="searchQuery"
              small
              hover
              :fields="tableColumns"
              :items="line_groups"
              responsive
              show-empty
              :empty-text="lang('t_noRecord')"
              :busy="line_groups.length == 0"
              :per-page="perPage"
              :current-page="currentPage"
            >
              <template #empty="scope">
                <b-container class="mt-5">
                  <b-row>
                    <b-col class="text-center text-muted mt-50">
                      <span>{{ scope.emptyText }}</span>
                    </b-col>
                  </b-row>
                </b-container>
              </template>
              <template #cell(lines)="data">
                <b-badge
                  v-for="(_line, _key) in data.value
                    .map((e) => e.display_name)
                    .slice(0, 5)"
                  :key="_key"
                  variant="light-secondary"
                  style="opacity: 0.7"
                  class="mr-50"
                >
                  {{ _line }}
                </b-badge>
              </template>

              <!-- Column: Actions -->
              <template #cell(actions)="data">
                <b-dropdown
                  boundary="viewport"
                  variant="link"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item @click="updateSelected(data.item)">
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">{{ lang("t_edit") }}</span>
                  </b-dropdown-item>

                  <b-dropdown-item @click="confirmText(data.item)">
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">{{
                      lang("t_delete")
                    }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </b-card-body>

          <b-card-footer class="py-75 border-0">
            <div
              class="d-flex justify-content-between d-flex align-items-center"
            >
              <div class="d-flex align-items-center mb-0">
                <!-- <span class="text-nowrap"> Showing 1 to </span> -->
                <b-form-select
                  size="sm"
                  v-model="perPage"
                  :options="['10', '25', '50']"
                  class="mr-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap text-muted font-small-3">
                  {{
                    lang("t_total") +
                    " " +
                    line_groups.length +
                    " " +
                    lang("t_row").toLowerCase()
                  }}
                </span>
              </div>

              <b-pagination
                size="sm"
                class="mb-0"
                v-model="currentPage"
                :per-page="perPage"
                align="right"
                :total-rows="line_groups.length"
                first-number
                last-number
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="16" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="16" />
                </template>
              </b-pagination>
            </div>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      :title="isEdit ? lang('t_edit') : lang('t_add')"
      centered
      v-model="modal_record_details"
      :ok-title="lang('t_save')"
      :cancel-title="lang('t_cancel')"
      @ok="set_line_group"
    >
      <b-form-group :label="lang('t_displayName')" label-for="lineGroup">
        <b-form-input
          :placeholder="lang('t_displayName')"
          id="lineGroup"
          v-model="line_group.display_name"
          trim
        />
      </b-form-group>
      <b-form-group :label="lang('t_lines')" label-for="lines">
        <v-select
          v-model="line_group.lines"
          :options="line_list"
          :placeholder="lang('t_lines')"
          label="display_name"
          multiple
          :closeOnSelect="false"
          :reduce="(val) => val"
        >
        </v-select>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import vSelect from 'vue-select'

import {
  VBModal,
  BModal,
  BButton,
  BRow,
  BCol,
  BBadge,
  BFormInput,
  BFormGroup,
  BCard,
  BCardText,
  BLink,
  BForm,
  BFormCheckbox,
  BCardFooter,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BFormSelect,
  BInputGroupPrepend,
  BInputGroup,
  BTable,
  BDropdown,
  BDropdownItem,
  BPagination,
  BContainer,
} from 'bootstrap-vue'

export default {
  computed: {
    items() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.line_groups.slice(start, end);
    },

  },
  data() {
    return {
      lang: _l(window.localStorage.getItem('language') || "tr"),
      is_progress: false,
      isEdit: false,
      modal_record_details: false,
      perPage: 10,
      currentPage: 1,
      searchQuery: '',
      selectable_lines: [],
      tmp_potential_lines: [],
      unselectable_lines: [],
      tmp_unpotential_lines: [],
      line_groups: [],
      line_list: [],
      line_group: {
        internal_name: '',
        display_name: '',
        lines: [],
      },
      tableColumns: [
        {
          key: "display_name",
          label: globalThis._lang("t_displayName"),
          sortable: true,
        },
        {
          key: "lines",
          label: globalThis._lang("t_lines"),
          sortable: true,
        },

        {
          key: "actions",
          label: '',
          sortable: false,
          thStyle: { width: '5vh' }
        },
      ],
      selected_item: {},


    }
  },
  components: {
    vSelect,
    VBModal,
    BModal,
    BButton,
    BRow,
    BCol,
    BBadge,
    BFormInput,
    BFormGroup,
    BCard,
    BCardText,
    BLink,
    BForm,
    BFormCheckbox,
    BCardFooter,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    BFormSelect,
    BInputGroupPrepend,
    BInputGroup,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BContainer,
  },
  directives: {
    'b-modal': VBModal,
  },
  mounted: function () {
    this.is_progress = true;
    this.get_line();
    this.get_line_groups();
    this.create_new();
    this.is_progress = false;
  },

  methods: {

    get_line_groups: async function () {
      this.is_progress = true;
      var response = (
        await this.$http_in.get(`/smgr/v1/LineGroup`)
      ).data;

      this.line_groups = [...response];
      this.is_progress = false;
    },
    get_line: async function () {
      var response = (
        await this.$http_in.get(`/smgr/v1/Line`)
      ).data;


      this.line_list = [...response];
    },
    editItem(item) {
      console.log('this is from parent:', item);
      this.selected_item = item;
      console.log('this.selected_item', this.selected_item);

      this.edit_modal = true;
      console.log(' this.modal', this.edit_modal);

    },
    delete_line_group: async function () {
      try {
        this.is_progress = true;
        var response = (
          await this.$http_in.delete(`/smgr/v1/LineGroup/${this.line_group.internal_name}`)
        ).data;
        this.is_progress = false;
        if (response != null || response != undefined) {
          this.$swal({
            icon: 'success',
            title: this.lang('t_processSuccess'),
            text: this.lang('t_customersHaveBeenSuccessfullyDeleted'),
            confirmButtonText: this.lang('t_ok'),
            customClass: {
              confirmButton: 'btn btn-success',
            },
          });
        }
      }
      catch (error) {
        if (error && error.response && error.response.data) {
          this.$swal({
            title: globalThis._lang("t_error"),
            text: `${error.response.data} kullanıldığı için kayıt silinemiyor.`,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
      } finally {
        this.is_progress = false;
      }

      this.create_new();
      this.get_line_groups();
    },
    clearAll() {
      this.$swal({
        title: this.lang('t_deleteRecord'),
        text: this.lang('t_areYouSureYouWantToDeleteTheSelectedRecord'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.lang('t_yes'),
        cancelButtonText: this.lang('t_cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',

        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.delete_line_group();
        }
      })
      // console.log("line_group", this.line_group);
      // var _list = this.line_group.linegroup_object.slice();
      // this.list = _list;
    },
    confirmText(item) {
      this.line_group = item;
      this.$swal({
        title: this.lang("t_deleteRecord"),
        text: this.lang("t_areYouSureYouWantToDeleteTheSelectedRecord"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.lang("t_yes"),
        cancelButtonText: this.lang("t_cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        console.log("res", result);
        if (result.value) {
          this.delete_line_group();
        }
      });
    },
    set_line_group: async function () {
      if (!this.line_group.display_name) {
        this.$swal({
          icon: 'warning',
          title: '',
          text: globalThis._lang("t_pleaseEnterTheLineGroupName"),
          confirmButtonText: this.lang('t_ok'),
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        });
        return;
      }

      this.is_progress = true;
      var response;
      if (!this.edit) {
        response = (
          await this.$http_in.post(
            `/smgr/v1/LineGroup`,
            { data: JSON.stringify(this.line_group) }
          )
        ).data;
      } else {
        response = (
          await this.$http_in.put(
            `/smgr/v1/LineGroup`,
            { data: JSON.stringify(this.line_group) }
          )
        ).data;
      }
      this.is_progress = false;
      if (response != null || response != undefined) {
        if (response.command == 'UPDATE') {
          this.$swal({
            icon: 'success',
            title: this.lang('t_processSuccess'),
            text: `${this.line_group.display_name} hat grubu başarılı bir şekilde güncellendi.`,
            confirmButtonText: this.lang('t_ok'),
            customClass: {
              confirmButton: 'btn btn-success',
            },
          });
        } else {
          this.$swal({
            icon: 'success',
            title: this.lang('t_processSuccess'),
            text: '',
            confirmButtonText: this.lang('t_ok'),
            customClass: {
              confirmButton: 'btn btn-success',
            },
          });
          this.create_new();
        }
        this.get_line_groups();
      }
    },
    updateSelected(selectedItem) {
      console.log("updateSelected", selectedItem);

      this.line_group = selectedItem;
      this.edit = true;
      this.modal_record_details = true;
    },
    create_new() {
      this.edit = false;
      this.line_group = {
        internal_name: '',
        display_name: '',
        lines: [],
      };
    },
  },

}

</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
