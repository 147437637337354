import { Node } from "@baklavajs/core";

export class SetAttributeNode extends Node {
  twoColumn = true;

  constructor() {
    super();
    this.type = "SetAttributeNode";
    this.name = "SetAttribute";
    this.addOption("Variable", "InputOption", "", undefined, { displayName: "Variable" })
    this.addOption("Value", "InputOption", "", undefined, { displayName: "Value" })
    this.addOption("Hide From Agent", "CheckboxOption")
    this.addOption("Show In Call Details", "CheckboxOption")

    this.addInputInterface("In");
    this.addOutputInterface("Next");



  }
}
