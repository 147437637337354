import { Node } from "@baklavajs/core";

export class MenuNode extends Node {
  twoColumn = true;

  constructor() {
    super();
    this.type = "MenuNode";
    this.name = "Menu";
    this.addInputInterface("In")
    this.addOutputInterface("Next")
    // this.addOutputInterface("Next")
    // this.addOutputInterface("Timeout")
    // this.addOutputInterface("Invalid")



    const languages = [
      {
        text: "Türkçe",
        value: '{"name":"Turkish","code":"tr-TR"}'
      },
      {
        text: "English",
        value: '{"name":"English","code":"en-EN"}'
      },
      {
        text: "Arabic",
        value: '{"name":"Arabic","code":"ar-AR"}'
      },
    ];
    // this.addOption("DTMF Digit", "IntegerOption", -1, undefined, { displayName: "DTMF Digit" })
    this.addOption("language", "SelectOption", '{"name":"Turkish","code":"tr-TR"}', undefined, {
      items: languages,
      displayName: "Language",
    })

    this.addOption("timeout", "IntegerOption", 3, undefined, { displayName: "Timeout (Sec)" })
    this.addOption("timeout_count", "IntegerOption", 1, undefined, { displayName: "Timeout Count" })
    this.addOption("retry", "IntegerOption", 1, undefined, { displayName: "Faulty Inputs" })
    this.addOption("can_recieve_exten", "CheckboxOption", false, undefined, { displayName: "Accept Extension" })


    // this.addOption("SidebarTest", "ButtonOption", () => ({ text: "any" }), "SelectOption")
  }
}
