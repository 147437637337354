import { Node } from "@baklavajs/core";

export class SMSNode extends Node {
  twoColumn = true;

  constructor() {
    super();
    this.type = "SMSNode";
    this.name = "SMS";
    this.addOption("SMS", "SelectOption", "", undefined, {
      items: []
    })
    this.addOption("RemoteNumber", "InputOption", "", undefined, { displayName: "RemoteNumber" });
    this.addOption("Text", "InputOption", "", undefined, { displayName: "Text" });

    this.addInputInterface("In");
    this.addOutputInterface("Next");
  }
}
