import { Node } from "@baklavajs/core";


export class LineGroupNode extends Node {
  twoColumn = true;

  constructor() {
    super();
    this.type = "LineGroupNode";
    this.name = "LineGroup";

    this.addOption("LineGroup", "SelectOption", '', undefined, {
      items: globalThis.ivr_targets.LineGroup,
      displayName: "LineGroup",
    })
    this.addInputInterface("In");

  }
}
