import { Node } from "@baklavajs/core";

export class IVRNode extends Node {
  twoColumn = true;

  constructor() {
    super();
    this.type = "IVRNode";
    this.name = "IVR";
    this.addOption("IVR", "SelectOption", "", undefined, {
      items: []
    })
    this.addInputInterface("In");
    // this.addOutputInterface("Next");
  }
}
