import { Node } from "@baklavajs/core";

export class QueueNode extends Node {
  twoColumn = true;

  constructor() {
    super();
    this.type = "QueueNode";
    this.name = "Queue";
    // this.addOption("DTMF Digit", "IntegerOption", -1)
    this.addOption("Queue", "SelectOption", "", undefined, {
      items: []
    })
    this.addInputInterface("In");
  }
}
