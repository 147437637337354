import { Node } from "@baklavajs/core";

export class StartNode extends Node {
  twoColumn = true;

  constructor() {
    super();
    this.type = "StartNode";
    this.name = "Start";
    this.addOutputInterface("Start", {});

  }
}
