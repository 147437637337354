<template>
  <div>
    <b-custom-spinner :is_progress="is_progress"></b-custom-spinner>
    <b-card>
      <b-card-text>
        <b-form-group>
          <v-select
            @input="updateSelected"
            v-model="ivr_info"
            :options="options"
            :placeholder="lang('t_survey')"
            :reduce="(option) => option"
            label="display_name"
          >
            <template v-slot:option="option">
              <!-- <span :class="'UserIcon'"></span> -->
              <span v-if="option.internal_name == 'new_record'">
                <feather-icon icon="PlusIcon" />
                {{ lang("t_addNewRecord") }}
              </span>
              <span v-else>{{ option.display_name }}</span>
            </template>
          </v-select>
          <b-form-input
            v-model="ivr_info.display_name"
            :placeholder="lang('t_displayName')"
            id="ivr"
            trim
            class="mt-50"
          />
        </b-form-group>

        <b-button
          @click="set_record"
          class="float-right ml-1"
          variant="primary"
          >{{ lang("t_save") }}</b-button
        >

        <b-button
          v-if="ivr_info.internal_name != 'new_record'"
          @click="delete_request"
          class="float-right"
          variant="danger"
          >{{ lang("t_delete") }}</b-button
        >
      </b-card-text>
    </b-card>
    <b-card>
      <b-card-text>
        <b-row>
          <b-badge
            style="cursor: pointer"
            draggable="false"
            class="ml-1 mr-50 badge-glow"
            variant="danger"
            @click="clearAll"
          >
            <feather-icon left icon="Trash2Icon" />
            {{ lang("t_clear") }}
          </b-badge>
          <draggable
            :list="list1"
            :group="{ name: 'g1', pull: 'clone', put: false }"
            :clone="cloneItem"
          >
            <b-badge
              style="cursor: move; width: 7.5em"
              class="mr-50 badge-glow"
              variant="primary"
              v-for="(element, i) in list1"
              :key="i"
            >
              {{ element.display_name }}
            </b-badge>
          </draggable>
        </b-row>

        <!-- <b-badge v-for="(item, i) in columns" :key="i" variant="primary">
            {{ item }}
          </b-badge> -->
      </b-card-text>
    </b-card>
    <b-card>
      <b-card-text class="pl-0">
        <nested-draggable
          class=""
          style="height: 700px; overflow-y: auto; width: 100%"
          :tasks="list"
          :click="editItem"
          type="ivr_root"
        />
      </b-card-text>
    </b-card>
    <b-modal
      centered
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      v-if="![null, '', undefined].includes(selected_item)"
      v-model="edit_modal"
      :title="selected_item.display_name"
    >
      <b-card-title class="headline">
        <h3>{{ selected_item.display_name }}</h3>
      </b-card-title>
      <!-- :title="selected_item.display_name" -->

      <!-- <b-form-input
        class="mt-1"
        v-model="selected_item.dtmf"
        v-if="
          selected_item.parent == 'menu' && selected_item.name != 'announce'
        "
        trim
        :placeholder="lang('t_DTMFdigit')"
      /> -->
      <b-form-input
        class="mt-1"
        v-model="selected_item.dtmf"
        v-if="
          selected_item.parent == 'menu' && selected_item.name != 'announce'
        "
        trim
        :placeholder="lang('t_DTMFdigit')"
      />
      <v-select
        v-model="selected_item.language"
        v-if="selected_item.name == 'menu'"
        :options="languages"
        :placeholder="lang('t_language')"
        :reduce="(option) => option"
        label="display_name"
      >
      </v-select>
      <v-select
        v-if="selected_item.name == 'announce'"
        v-model="selected_item.target"
        :options="announcements"
        :placeholder="lang('t_announcementFile')"
        :reduce="(option) => option"
        label="display_name"
      >
      </v-select>
      <v-select
        v-model="selected_item.target"
        v-if="selected_item.name == 'ivr'"
        :options="ivr"
        :placeholder="lang('t_IVR')"
        :reduce="(option) => option"
        label="display_name"
      >
      </v-select>
      <b-form-checkbox
        class="mt-1"
        v-if="
          selected_item.name == 'announce' && selected_item.parent == 'menu'
        "
        v-model="selected_item.can_recieve_dtmf"
        switch
        inline
      >
        {{ lang("t_DTMFAccept") }}
      </b-form-checkbox>

      <v-select
        v-if="selected_item.name == 'survey'"
        v-model="selected_item.target"
        :options="announcements"
        :placeholder="lang('t_announcementFile')"
        :reduce="(option) => option"
        label="display_name"
      >
      </v-select>

      <b-form-input
        class="mt-1"
        v-model="selected_item.timeout"
        trim
        v-if="selected_item.name == 'menu'"
        :placeholder="lang('t_timeOutLengthSec')"
      />
      <b-form-input
        class="mt-1"
        v-model="selected_item.timeout"
        trim
        v-if="selected_item.name == 'survey'"
        :placeholder="lang('t_timeOutLengthSec')"
      />
      <b-form-input
        class="mt-1"
        v-model="selected_item.retry"
        trim
        v-if="selected_item.name == 'survey'"
        :placeholder="lang('t_maxInvalidInputCount')"
        type="number"
      />
      <b-form-input
        class="mt-1"
        v-model="selected_item.survey"
        trim
        v-if="selected_item.name == 'survey'"
        :placeholder="lang('t_survey')"
      />
      <b-form-input
        class="mt-1"
        v-model="selected_item.survey_length"
        trim
        v-if="selected_item.name == 'survey'"
        :placeholder="lang('t_surveyLength')"
      />
      <b-form-input
        class="mt-1"
        v-model="selected_item.timeout_retry"
        trim
        v-if="selected_item.name == 'menu'"
        :placeholder="lang('t_timeOutCount')"
        type="number"
      />
      <b-form-input
        class="mt-1"
        v-model="selected_item.retry"
        trim
        v-if="selected_item.name == 'menu'"
        :placeholder="lang('t_maxInvalidInputCount')"
        type="number"
      />

      <b-form-input
        class="mt-1"
        v-model="selected_item.retry"
        trim
        v-if="selected_item.name == ''"
        :placeholder="lang('t_maxInvalidInputCount')"
        type="number"
      />
      <!-- <b-form-checkbox
        class="mt-1"
        v-if="selected_item.name == 'menu'"
        v-model="selected_item.can_recieve_exten"
        switch
        inline
      >
        {{ lang("t_acceptDTMFExtension") }}
      </b-form-checkbox> -->
      <b-button
        class="mt-1 float-right"
        variant="primary"
        @click="edit_modal = false"
        >{{ lang("t_confirm") }}</b-button
      >
    </b-modal>
  </div>
</template>

<script>
import vSelect from "vue-select";
import nestedDraggable from "./NestedDraggable";
import draggable from "vuedraggable";

import {
  BFormCheckbox,
  VBModal,
  BModal,
  BButton,
  BRow,
  BCol,
  BBadge,
  BFormInput,
  BFormGroup,
  BCard,
  BCardText,
  BLink,
} from "bootstrap-vue";

export default {
  components: {
    BFormCheckbox,
    nestedDraggable,
    BModal,
    BButton,
    BRow,
    BCol,
    BBadge,
    vSelect,
    BCard,
    BCardText,
    BLink,
    BFormInput,
    BFormGroup,
    draggable,
  },
  directives: {
    "b-modal": VBModal,
  },
  mounted: function () {},
  methods: {
    editItem(item) {
      console.log("this is from parent:", item);
      this.selected_item = item;
      console.log("this.selected_item", this.selected_item);

      this.edit_modal = true;
      console.log(" this.modal", this.edit_modal);
    },
    clearAll() {
      this.$swal({
        title: this.lang("t_deleteRecord"),
        text: this.lang("t_areYouSureYouWantToDeleteTheSelectedRecord"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.lang("t_yes"),
        cancelButtonText: this.lang("t_cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.list = [];
        }
      });
      // console.log("ivr_info", this.ivr_info);
      // var _list = this.ivr_info.ivr_object.slice();
      // this.list = _list;
    },
    updateSelected() {
      console.log("updateSelected");
      if (this.ivr_info.internal_name == "new_record") {
        this.edit = false;
        this.list = [];
        return;
      }
      this.edit = true;
      // this.ivr_info = { ...this.ivr_info };
      var _list = this.ivr_info.ivr_object.slice();
      this.list = _list;
    },
    cloneItem(item) {
      console.log(item);
      return { ...item, tasks: [], tasks2: [] };
    },
    async get_records() {
      this.is_progress = true;
      var response = (
        await this.$http_in.get(`smgr/v1/SurveyIvrManagementTargets`)
      ).data;

      this.options = [
        {
          internal_name: "new_record",
          display_name: "",
          ivr_object: [],
        },
        ...response.Survey,
      ];

      this.tc = response.TimeConditions;
      this.ivr = response.Ivr;
      this.queues = response.Queues;
      this.announcements = response.Announcement.filter(
        (e) => !e.is_hold_sound
      );
      this.local_users = response.LocalUsers;
      this.is_progress = false;
    },
    set_record: async function () {
      if (this.list.length <= 0 || this.ivr_info.display_name == "") {
        this.$swal({
          title: globalThis._lang("t_warning"),
          text: globalThis._lang("t_pleaseDoNotLeaveAnyBlankFields"),
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        return;
      }

      this.ivr_info = {
        ...this.ivr_info,
        ivr_object: this.list,
      };

      this.is_progress = true;
      var response;
      if (!this.edit) {
        response = (
          await this.$http_in.post(`smgr/v1/SurveyIvr`, {
            data: JSON.stringify(this.ivr_info),
          })
        ).data;
      } else {
        response = (
          await this.$http_in.put(`smgr/v1/SurveyIvr`, {
            data: JSON.stringify(this.ivr_info),
          })
        ).data;
      }
      this.is_progress = false;
      if (response && response.acknowledged) {
        this.$swal({
          title: globalThis._lang("t_processSuccess"),
          text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }

      this.get_records();
    },
    delete_request() {
      this.$swal({
        title: this.lang("t_deleteRecord"),
        text: this.lang("t_areYouSureYouWantToDeleteTheSelectedRecord"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.lang("t_yes"),
        cancelButtonText: this.lang("t_cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.delete_record();
        }
      });
    },
    delete_record: async function () {
      this.is_progress = true;
      this.modal_delete = false;
      var response = (
        await this.$http_in.delete(`smgr/v1/SurveyIvr/${this.ivr_info._id}`)
      ).data;
      this.is_progress = false;
      if (response && response.acknowledged) {
        this.$swal({
          icon: "success",
          title: this.lang("t_processSuccess"),
          text: this.lang("t_customersHaveBeenSuccessfullyDeleted"),
          confirmButtonText: this.lang("t_ok"),
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      }

      this.create_new_ivr();
      this.get_records();
    },
    create_new_ivr() {
      this.edit = false;
      this.ivr_info = {};
      this.list = [];
    },
  },
  mounted: function () {
    this.get_records();
  },
  data() {
    return {
      lang: _l(window.localStorage.getItem("language") || "tr"),
      is_progress: false,
      ivr_info: {
        internal_name: "new_record",
        display_name: "",
        ivr_object: [],
      },
      selected_item: {},
      edit_modal: false,
      options: [
        // {
        //   internal_name: 'new_record',
        //   display_name: '',
        //   ivr_object: [],
        // },
      ],
      languages: [
        {
          internal_name: "tr-TR",
          display_name: globalThis._lang("t_turkish"),
        },
        {
          internal_name: "en-EN",
          display_name: globalThis._lang("t_english"),
        },
        {
          internal_name: "ar-AR",
          display_name: globalThis._lang("t_arabic"),
        },
        {
          internal_name: "ru-RU",
          display_name: globalThis._lang("t_russian"),
        },
      ],
      tc: [],
      ivr: [],
      queues: [],
      announcements: [],
      local_users: [],
      list: [],
      list1: [
        {
          name: "menu",
          display_name: globalThis._lang("t_menu"),
          tasks: [],
          is_dtmf: false,
          dtmf: "",
          timeout: "5",
          timeout_retry: "2",
          retry: "2",
          language: {
            internal_name: "tr-TR",
            display_name: globalThis._lang("t_turkish"),
          },
          can_recieve_exten: "",
        },
        {
          name: "survey",
          display_name: globalThis._lang("t_survey"),
          is_dtmf: false,
          dtmf: "",
          timeout: "3",
          retry: "2",
          announcement: "",
          survey_length: 6,
          survey: "",
        },
        {
          name: "announce",
          display_name: globalThis._lang("t_announcement"),
          tasks: [],
          is_dtmf: false,
          dtmf: "",
          can_recieve_dtmf: false,
          target: { internal_name: "", display_name: "" },
        },
        {
          name: "hangup",
          display_name: globalThis._lang("t_hangup"),
          tasks: [],
          is_dtmf: false,
          dtmf: "",
        },
        {
          name: "ivr",
          display_name: globalThis._lang("t_IVR"),
          tasks: [],
          is_dtmf: true,
          dtmf: "",
          target: {
            internal_name: "",
            display_name: "",
          },
        },
      ],
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
