import { Node } from "@baklavajs/core";

export class TTSNode extends Node {
  twoColumn = true;

  constructor() {
    super();
    this.type = "TTSNode";
    this.name = "TTS";
    this.addOption("TTS", "SelectOption", "", undefined, {
      items: []
    })
    this.addOption("Text", "TextArea", "Text", undefined, { displayName: "Text" });

    this.addInputInterface("In");
    this.addOutputInterface("Next");

  }
}
