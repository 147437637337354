<template>
  <b-button @click="handleClick" class="float-right">İstek Yap</b-button>
</template>

<script>
import {
  BButton,
} from "bootstrap-vue";
export default {
  components: {
    BButton
  },
  props: ["value"],

  methods: {
    handleClick() {
      console.log(this.value);
      const foundNode = globalThis.editor.nodes.find(node => node.id === this.value);
      console.log("foundNode", foundNode);
      // _type,_url,_body,_headers,_variable
      globalThis.ivr.SendRequest(
        foundNode.options.get("Type")._value,
        foundNode.options.get("URL")._value,
        foundNode.options.get("Body")._value,
        foundNode.options.get("Header")._value,
        foundNode.options.get("Variable")._value,
      );
      console.log("Bu düğümde işlem yapılıyor:", foundNode);
      // Diğer işlemler...
    }
  },
  mounted: function () {
    console.log(this.value);
  },
  data() {
    return {
      placeHolder: '',
    }
  },
}
</script>
