<template>
  <div>
    <b-tabs
      lazy
      pills
      active-nav-item-class="bg-light-primary border-0 shadow-none cursor-pointer"
    >
      <b-tab v-if="menu.includes('lines')" :title="lang('t_lines')" active>
        <lines></lines>
      </b-tab>
      <!-- <b-tab v-if="menu.includes('line_groups')" :title="lang('t_lineGroup')">
        <line-group></line-group>
      </b-tab> -->
      <b-tab v-if="menu.includes('dial_plans')" :title="lang('t_dialPlans')">
        <dial-plans></dial-plans>
      </b-tab>
      <b-tab v-if="menu.includes('ivr')" :title="lang('t_IVR')">
        <ivr></ivr>
      </b-tab>
      <!-- <b-tab v-if="menu.includes('v_ivr')" :title="lang('t_vIVR')">
        <variable-ivr></variable-ivr>
      </b-tab> -->
      <!-- <b-tab v-if="menu.includes('survey')" :title="lang('t_survey')">
        <survey></survey>
      </b-tab> -->
      <b-tab
        v-if="menu.includes('announcements')"
        :title="lang('t_announcements')"
      >
        <announcements></announcements>
      </b-tab>
      <b-tab v-if="menu.includes('inbound_routes')" :title="lang('t_routes')">
        <inbound-routes></inbound-routes>
      </b-tab>
      <b-tab
        v-if="menu.includes('time_conditions')"
        :title="lang('t_timeCondition')"
      >
        <time-conditions></time-conditions>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BContainer, BFormRadioGroup, BFormGroup, BRow, BCol, BCard, BCardText, BLink, BButton, BTabs, BTab } from 'bootstrap-vue'
// import SmsSender from './SmsSender.vue'
import Announcements from './Announcements/list/List.vue'
import InboundRoutes from './InboundRoutes/list/List.vue'
import DialPlans from './DialPlans/list/List.vue'
import ivr from './IVR/Main.vue'
import VariableIvr from './Variable_IVR/IVR.vue'
import Survey from './Survey/Survey.vue'
import Lines from './Lines/list/List.vue'
import TimeConditions from './TimeConditions/list/List.vue'
import LineGroup from './LineGroup/LineGroup.vue'


export default {
  data() {
    return {
      lang: _l(window.localStorage.getItem('language') || "tr"),
      selectedButton: 'lines',
      menu: [],
    }
  },
  components: {
    BTabs, BTab,
    BContainer,
    Announcements,
    InboundRoutes,
    ivr,
    VariableIvr,
    Survey,
    Lines,
    TimeConditions,
    DialPlans,
    LineGroup,
    BFormRadioGroup,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BButton,
  },
  mounted: async function () {
    this.menu = globalThis.permissions["menu"];
    if (this.menu.includes('lines')) {
      this.selectedButton = 'lines';
    }
  }
}
</script>

<style>
</style>
