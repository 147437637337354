import { Node } from "@baklavajs/core";

export class ExtensionNode extends Node {
  twoColumn = true;

  constructor() {
    super();
    this.type = "ExtensionNode";
    this.name = "Extension";
    this.addOption("Extension", "SelectOption", '', undefined, {
      items: globalThis.ivr_targets.Announcement,
      displayName: "Extension",
    })
    this.addInputInterface("In");
  }
}
