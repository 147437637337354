import { Node } from "@baklavajs/core";

export class TransferNode extends Node {
  twoColumn = true;

  constructor() {
    super();
    this.type = "TransferNode";
    this.name = "Transfer";
    this.addOption("RemoteNumber", "InputOption", "", undefined, { displayName: "RemoteNumber" });
    this.addOption("LineGroup", "SelectOption", "", undefined, {
      items: globalThis.IVRElements.LineGroupNode
    })
    this.addOption("ContinueInDialPlan", "CheckboxOption")

    this.addInputInterface("In");
  }
}
