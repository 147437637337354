import { Node } from "@baklavajs/core";

export class MailNode extends Node {
  twoColumn = true;

  constructor() {
    super();
    console.log("asdfg");
    this.type = "MailNode";
    this.name = "Mail";
    this.addInputInterface("In");
    this.addOutputInterface("Next");

    this.addOption("Provider", "SelectOption", "", undefined, {
      items: []
    })
    this.addOption("Template", "SelectOption", "", undefined, {
      items: []
    })
    this.addOption("Subject", "InputOption", "", undefined, { displayName: "Subject" });
    this.addOption("To", "InputOption", "", undefined, { displayName: "To" });
    this.addOption("Cc", "InputOption", "", undefined, { displayName: "Cc" });

  }
}


