import { NodeBuilder } from '@baklavajs/core'

export const TimeConditionNode = new NodeBuilder("TimeConditionNode", { twoColumn: true })
  .addInputInterface("In")
  .addOutputInterface("Match")
  .addOutputInterface("No Match")
  // .addOption("DTMF Digit", "InputOption")
  .addOption("TimeCondition", "SelectOption", "", undefined, {
    items: []
  })
  .build();
