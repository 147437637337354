import { Node } from "@baklavajs/core";


export class AnnouncementNode extends Node {
  twoColumn = true;

  constructor() {
    super();
    this.type = "AnnouncementNode";
    this.name = "Announcement";
    // this.addOption("DTMF Digit", "IntegerOption", -1)
    // this.addOption("Announcement", "SelectOption", "", undefined, {
    //   items: []
    // })
    this.addOption("Announcement", "SelectOption", '', undefined, {
      items: globalThis.ivr_targets.Announcement,
      displayName: "Announcement",
    })
    this.addOption("Accept DTMF", "CheckboxOption")
    this.addOption("cPlayButton", "cPlayButton", this.id);

    this.addInputInterface("In");
    this.addOutputInterface("Next");

  }
}
